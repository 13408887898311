export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_TEMPLATE_ID = "SET_TEMPLATE_ID";
export const GET_PREV_TEMPLATES = "GET_PREV_TEMPLATES";
export const PREV_TEMPLATES_SUCCESS = "PREV_TEMPLATES_SUCCESS";
export const CLEAR_TEMPLATE_STATE = "CLEAR_TEMPLATE_STATE";

export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS";
export const FETCH_LANGUAGES_FAIL = "FETCH_LANGUAGES_FAIL";

export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGES_SUCCESS = "SET_LANGUAGES_SUCCESS";
export const SET_LANGUAGES_FAIL = "SET_LANGUAGES_FAIL";

export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";
export const SNACKBAR_INFO = "SNACKBAR_INFO";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const SET_FLOW_STATE = "SET_FLOW_STATE";
export const CLEAR_FLOW_STATE = "CLEAR_FLOW_STATE";
export const UPDATE_FLOW_STATE = "UPDATE_FLOW_STATE";

export const STACK_FB_STATE = 'STACK_FB_STATE';
export const SET_FB_POST_LIKE = 'SET_FB_POST_LIKE';
export const SET_FB_POST_UNLIKE = 'SET_FB_POST_UNLIKE';
export const SET_FB_POST_COMMENT = 'SET_FB_POST_COMMENT';
export const CREATE_FB_POST = 'CREATE_FB_POST';
export const SET_FB_LOADING = 'SET_FB_LOADING';
export const SET_FB_POST_IDS_AND_COUNT = 'SET_FB_POST_IDS_AND_COUNT';
export const SET_FB_POST_FETCH_FINISH = 'SET_FB_POST_FETCH_FINISH';
export const UPDATE_FACEBOOK_PAGE_STATE = 'UPDATE_FACEBOOK_PAGE_STATE';
export const CLEAR_FB_STATE = 'CLEAR_FB_STATE';
export const UNDO_POST = 'UNDO_POST';
export const INCREMENT_REPLIES_COUNT = 'INCREMENT_REPLIES_COUNT';
export const INCREMENT_QUOTE_RETWEET_COUNT = 'INCREMENT_QUOTE_RETWEET_COUNT';
export const DECREMENT_QUOTE_RETWEET_COUNT = 'DECREMENT_QUOTE_RETWEET_COUNT';

export const SET_POST_REPORT = 'SET_POST_REPORT';
export const SET_POST_UNREPORT = 'SET_POST_UNREPORT';

export const SET_REGISTER_META_DATA = 'SET_REGISTER_META_DATA';
export const CLEAR_REGISTER_META_DATA = 'CLEAR_REGISTER_META_DATA';
